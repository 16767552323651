import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { usePartyStore } from '../store/party'

export default defineNuxtRouteMiddleware((to) => {
  const $party = usePartyStore()
  if (
    to.name?.toString().startsWith('party-uuid-characters') &&
    to.name.toString() !== 'party-uuid-characters-new'
  ) {
    if ($party.name) {
      if (!$party.characters.length) {
        return navigateTo({ name: 'party-uuid-characters-new' })
      } else if (to.name.toString() === 'party-uuid-characters') {
        return navigateTo({
          name: 'party-uuid-characters-character',
          params: { character: $party.characters[0].id },
        })
      }
    }
  }
})
